import fetch from '@/utils/fetch'

export function getearlybirdList(query) {
  return fetch({
    url: '/earlybird/earlybird/list',
    method: 'get',
    params: query
  })
}

export function getearlybirdDetail(query) {
  return fetch({
    url: '/earlybird/earlybird/detail',
    method: 'get',
    params: query
  })
}

export function getearlybirdCreate(query) {
  return fetch({
    url: '/earlybird/earlybird/create',
    method: 'post',
    params: query
  })
}

export function getearlybirdDelete(query) {
  return fetch({
    url: '/earlybird/earlybird/delete',
    method: 'post',
    params: query
  })
}

export function getenableEarlybird(query) {
  return fetch({
    url: '/earlybird/earlybird/enable',
    method: 'post',
    params: query
  })
}

// 上传早鸟购商品
export function postespieruploadfile(query) {
  return fetch({
    url: `/espier/upload_file?earlybird_id=${query.distributor_id}`,
    method: 'post',
    params: query
  })
}
// 上传早鸟购商品结果列表
export function getespieruploadfiles(query) {
  return fetch({
    url: '/espier/upload_files',
    method: 'get',
    params: query
  })
}
// 获取早鸟狗绑定商品sku维度
export function getearlybirdgoodslist(query) {
  return fetch({
    url: '/earlybird/earlybirdgoods/list',
    method: 'get',
    params: query
  })
}
// 修改早鸟狗商品
export function postearlybirdsavegoods(query) {
  return fetch({
    url: '/earlybird/earlybird/savegoods',
    method: 'post',
    params: query
  })
}