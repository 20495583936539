import fetch from '../utils/fetch'

//api日志列表
export function apiLogList(query) {
  return fetch({
    url: '/mallcoo/apilogs/list',
    method: 'get',
    params: query
  })
}
//日志详情
export function apiLogGet(query) {
  return fetch({
    url: '/mallcoo/apilogs/get',
    method: 'get',
    params: query
  })
}
//导出
export function apiLogsExport(query){
  return fetch({
    url: '/mallcoo/apilogs/export',
    method: 'get',
    params: query
  })
}
//所有接口
export function apiAllApis(query) {
  return fetch({
    url: '/mallcoo/apilogs/allapis',
    method: 'get',
    params: query
  })
}
//分类
export function apiCat(query) {
  return fetch({
    url: '/mallcoo/apilogs/cat',
    method: 'get',
    params: query
  })
}
//第三方
export function apiPartner(query) {
  return fetch({
    url: '/mallcoo/apilogs/partner',
    method: 'get',
    params: query
  })
}
