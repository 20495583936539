import fetch from '../utils/fetch'

//创建通知消息
export function pagebannercreate(query) {
    return fetch({
        url: '/pagebanner/create',
        method: 'post',
        params: query
    })
}
// 通知消息详情
export function pagebannerdetail(query) {
    return fetch({
        url: '/pagebanner/detail',
        method: 'get',
        params: query
    })
}
// 通知消息列表
export function pagebannerlist(query) {
    return fetch({
        url: '/pagebanner/list',
        method: 'get',
        params: query
    })
}
// 删除通知消息
export function pagebannerdelete(query) {
    return fetch({
        url: '/pagebanner/delete',
        method: 'post',
        params: query
    })
}
// 修改通知状态
export function pagebannerenable(query) {
    return fetch({
        url: '/pagebanner/enable',
        method: 'post',
        params: query
    })
}


// 创建弹框
export function pagepromptcreate(query) {
    return fetch({
        url: '/pageprompt/create',
        method: 'post',
        params: query
    })
}
// 弹框详情
export function pagepromptdetail(query) {
    return fetch({
        url: '/pageprompt/detail',
        method: 'get',
        params: query
    })
}
// 通知消息列表
export function pagepromptlist(query) {
    return fetch({
        url: '/pageprompt/list',
        method: 'get',
        params: query
    })
}
// 删除通知消息
export function pagepromptdelete(query) {
    return fetch({
        url: '/pageprompt/delete',
        method: 'post',
        params: query
    })
}
// 修改通知状态
export function pagepromptenable(query) {
    return fetch({
        url: '/pageprompt/enable',
        method: 'post',
        params: query
    })
}

//创建订阅
export function promsubcreate(query) {
    return fetch({
        url: '/promsub/create',
        method: 'post',
        params: query
    })
}
// 订阅详情
export function promsubdetail(query) {
    return fetch({
        url: '/promsub/detail',
        method: 'get',
        params: query
    })
}
// 订阅列表
export function promsublist(query) {
    return fetch({
        url: '/promsub/list',
        method: 'get',
        params: query
    })
}
// 删除订阅
export function promsubdelete(query) {
    return fetch({
        url: '/promsub/delete',
        method: 'post',
        params: query
    })
}
// 修改订阅
export function promsubenable(query) {
    return fetch({
        url: '/promsub/enable',
        method: 'post',
        params: query
    })
}

